.App-link {
  color: #61dafb;
}

main{
  width: 100%;
  text-align: center;
}

.galeriaImagenes > img:hover  {
  animation: mianimacion 1s infinite;
}

.resultadoDiv{
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
}
.resultadoDiv>figure{
  width: 30%;
}
.resultadoDiv>figure>img{
  width: 100%;
}
.resultadoMsj{
  font-size:  calc(1em + 1vw);
  width: 100%;  
}

.img{
  border-width: 10px;
  border-radius: 50%;
  border-style: solid; 
}

.imgGanador {
  border-color: green;
}
.imgPerdedor {
  border-color: red;
}
.imgEmpate {
  border-color: yellow;
}


@media (min-width: 800px) {
  main{
    width: 800px;
  }
  .resultadoDiv > .resultadoMsj{
    width: 100%;
  }
  .galeriaImagenes{
    width: 800px;
    text-align: center;
  }
  .galeriaImagenes > img{
    display: inline;
    float: left;
    width: 20%;
  }
}

@media (max-width: 800px) {
  .galeriaImagenes > img{
    display: inline;
    float: left;
    width: 20%;
    border-width: 5px;
    width: 800px;  
  }
  .galeriaImagenes{
    width: 100%;
  }
  .galeriaImagenes > img{
      display: inline;
      width: 20%;      
  }
}

@media (max-width: 400px) {
  .resultadoDiv>figure{
    width: 40%;
    float: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;
  }
  .img{
    border-width: 10px;
    border-radius: 50%;
    border-style: solid; 
  }
}

@keyframes mianimacion {
  0% {
      transform: translateY(0px);
  }
  20%{
      transform: translateY(5px);
  }
  50% {
      transform: translateY(10px);
  }
  100% {
      transform: translateY(5px);
  }               
} 